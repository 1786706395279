import React from 'react';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LabelInput from './LabelInput';
import CaptionInput from './CaptionInput';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface MultiSelectInputProps {
    id: string;
    label: string;
    value: string[]; // Ensure this is an array of strings
    items: {
        id: string;
        value: string | number;
        name: string;
    }[];
    handleChange: (field: string, value: any[]) => void; // Update this to expect an array
    handleBlur: (e: React.FocusEvent<any>) => void;
    hasSubmitted: boolean;
    errors?: string[] | string;
    touched?: boolean;
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
    submitForm?: () => Promise<void>;
    sortByName?: boolean;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
    caption,
    id,
    label,
    value,
    items,
    hasSubmitted,
    handleBlur,
    errors,
    touched,
    handleChange,
    enableLabel,
    isMobile,
    submitForm,
    sortByName,
}) => {
    const { theme } = useCustomTheme();
    const labelId = `${id}-label`;

    const handleCloseChange = async () => {
        if (submitForm) {
            await submitForm();
        }
    };

    const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        handleChange(id, typeof value === 'string' ? value.split(',') : value); // Ensure value is an array
    };

    const isValueSelected = value.length > 0; // Check if any value is selected

    return (
        <FormControl fullWidth margin="normal">
            <Box display="flex" alignItems="center">
                <Grid
                    container
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    spacing={2}
                >
                    {!enableLabel ? (
                        <LabelInput
                            label={label}
                            enableLabel={enableLabel}
                            isMobile={isMobile}
                            caption={caption}
                        />
                    ) : (
                        <InputLabel
                            sx={{
                                color: isValueSelected
                                    ? theme.palette.text.secondary
                                    : theme.palette.text.disabled,
                            }}
                            id={labelId}
                        >
                            {label}
                        </InputLabel>
                    )}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                        lg={enableLabel ? 12 : 9.8}
                        xl={enableLabel ? 12 : 10.25}
                    >
                        <Select
                            fullWidth
                            labelId={labelId}
                            name={id}
                            id={id}
                            multiple
                            value={value}
                            label={enableLabel ? label : undefined}
                            onChange={handleSelectChange}
                            onClose={handleCloseChange}
                            onBlur={(e) => {
                                handleBlur(e);
                            }}
                            error={touched && Boolean(errors)}
                            disabled={hasSubmitted}
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                    }}
                                >
                                    {selected.map((value) => {
                                        const selectedItem = items.find(
                                            (item) => item.value === value
                                        );
                                        return selectedItem ? (
                                            <Chip
                                                key={selectedItem.id}
                                                label={selectedItem.name.toUpperCase()}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.text
                                                        .primary,
                                                }}
                                            />
                                        ) : null;
                                    })}
                                </Box>
                            )}
                            sx={{
                                textAlign: 'left',
                                '& .MuiSelect-select': {
                                    textAlign: 'left',
                                },
                            }}
                        >
                            {items
                                .slice() // Create a shallow copy of the array to avoid mutating the original array
                                .sort((a, b) =>
                                    sortByName
                                        ? a.name.localeCompare(b.name)
                                        : 0
                                ) // Sort by name if sortByName is true
                                .map((item) => (
                                    <MenuItem key={item.id} value={item.value}>
                                        {item.name.toUpperCase()}
                                    </MenuItem>
                                ))}
                        </Select>
                        {touched && errors && (
                            <FormHelperText
                                sx={{ color: theme.palette.error.main }}
                            >
                                {errors}
                            </FormHelperText>
                        )}
                        {enableLabel && caption && (
                            <CaptionInput caption={caption} />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </FormControl>
    );
};

export default MultiSelectInput;
