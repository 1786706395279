import React, { useEffect, useState } from 'react';
import { CardContent, Fade } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridDensity,
    GridFilterModel,
    GridRowSelectionModel,
    GridSortModel,
    GridToolbar,
} from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { fadeTimeout } from '../../../helpers/Themes';

interface DataTableProps {
    children?: React.ReactNode;
    isMobile: boolean;
    padding?: number;
    columns: GridColDef[];
    rows: object[];
    setSelectedRows?: (selectedRows: GridRowSelectionModel) => void;
    storedSettingsName: string;
}

const DataTable: React.FC<DataTableProps> = ({
    children,
    isMobile,
    padding,
    columns,
    rows,
    setSelectedRows,
    storedSettingsName,
}) => {
    const { theme } = useCustomTheme();
    const paginationModel = { page: 0, pageSize: 10 };

    const modifiedColumns = columns.map((column) => ({
        ...column,
        flex: 1,
        headerClassName: 'custom-header',
    }));

    const storedSettingsNamePrefix = 'dataTableFilterModel';
    const storedDensityNamePrefix = 'dataTableDensityModel';
    const storedVisibilityNamePrefix = 'dataTableVisibilityModel'; // New prefix for visibility
    const storedSortNamePrefix = 'dataTableSortModel'; // New prefix for sorting

    const [filterModel, setFilterModel] = useState<GridFilterModel | null>(
        () => {
            const storedFilterModel = localStorage.getItem(
                storedSettingsNamePrefix + storedSettingsName
            );

            // Check if storedFilterModel is null, return null if so
            if (storedFilterModel === null) return null;

            return JSON.parse(storedFilterModel);
        }
    );

    const [densityModel, setDensityModel] = useState<GridDensity | null>(() => {
        const storedDensityModel = localStorage.getItem(
            storedDensityNamePrefix + storedSettingsName
        );

        // Check if storedFilterModel is null, return null if so
        if (storedDensityModel === null) return null;

        return JSON.parse(storedDensityModel);
    });

    // New state for column visibility
    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>(() => {
            const storedVisibilityModel = localStorage.getItem(
                storedVisibilityNamePrefix + storedSettingsName
            );

            if (storedVisibilityModel === null) return {};

            return JSON.parse(storedVisibilityModel);
        });

    // New state for sort model
    const [sortModel, setSortModel] = useState<GridSortModel>(() => {
        const storedSortModel = localStorage.getItem(
            storedSortNamePrefix + storedSettingsName
        );
        if (storedSortModel === null) return [];
        return JSON.parse(storedSortModel);
    });

    useEffect(() => {
        localStorage.setItem(
            storedDensityNamePrefix + storedSettingsName,
            JSON.stringify(densityModel)
        );
    }, [densityModel]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        localStorage.setItem(
            storedSettingsNamePrefix + storedSettingsName,
            JSON.stringify(filterModel)
        );
    }, [filterModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Effect to save column visibility model to localStorage
    useEffect(() => {
        localStorage.setItem(
            storedVisibilityNamePrefix + storedSettingsName,
            JSON.stringify(columnVisibilityModel)
        );
    }, [columnVisibilityModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Effect to save sort model to localStorage
    useEffect(() => {
        localStorage.setItem(
            storedSortNamePrefix + storedSettingsName,
            JSON.stringify(sortModel)
        );
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card>
            <CardContent>
                <Fade in={true} timeout={fadeTimeout}>
                    <div>
                        {children}

                        <DataGrid
                            getRowId={(row) => row.uuid} // Specify custom ID field
                            rows={rows}
                            columns={modifiedColumns}
                            columnVisibilityModel={
                                columnVisibilityModel
                                    ? columnVisibilityModel
                                    : undefined
                            }
                            sortModel={sortModel ? sortModel : undefined}
                            filterModel={filterModel ? filterModel : undefined}
                            density={densityModel ? densityModel : undefined}
                            onColumnResize={(newModel, oldModel) => {
                                console.log(newModel);
                            }}
                            onSortModelChange={(newModel) =>
                                setSortModel(newModel)
                            } // Update sort model
                            onDensityChange={(model) => setDensityModel(model)}
                            onFilterModelChange={(model) =>
                                setFilterModel(model)
                            }
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            } // Update visibility mode
                            initialState={{
                                pagination: { paginationModel },
                            }}
                            pageSizeOptions={[5, 10, 25, 50, 100]}
                            checkboxSelection
                            onRowSelectionModelChange={(selectionModel) => {
                                setSelectedRows?.(selectionModel); // Directly pass selection model
                            }}
                            disableRowSelectionOnClick
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                            sx={{
                                paddingTop: 3,
                                border: 'none',
                                // Table Body Font
                                color: theme.palette.text.secondary,
                                fontSize: theme.typography.body2.fontSize,
                                fontWeight: theme.typography.body2.fontWeight,
                                // Table Header Dont
                                '--unstable_DataGrid-headWeight':
                                    theme.typography.h6.fontWeight,
                                '& .custom-header': {
                                    fontSize: theme.typography.h6.fontSize,
                                    color: theme.palette.text.secondary,
                                },
                                // Header Separators
                                '& .MuiDataGrid-iconSeparator': {
                                    //    display: 'none',
                                    color: theme.palette.primary.light,
                                },
                                // Row Separators
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: theme.palette.primary.light,
                                },
                                '--DataGrid-rowBorderColor':
                                    theme.palette.primary.light,
                                // Pagination Text
                                '& .MuiTablePagination-root': {
                                    color: theme.palette.text.secondary,
                                },
                                // Pagination Arrows
                                '& .MuiIconButton-root': {
                                    color: theme.palette.text.secondary,
                                },
                                // Checkboxes
                                '& .MuiCheckbox-colorPrimary': {
                                    color: theme.palette.primary.main,
                                },
                            }}
                        />
                    </div>
                </Fade>
            </CardContent>
        </Card>
    );
};

export default DataTable;
