import Box from '@mui/material/Box';
import React from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import GradientBorder from '../borders/GradientBorder';
import { getOverallPadding } from '../../../helpers/Themes';

interface PopupModalProps {
    isMobile: boolean;
    padding?: number;
    simpleContent?: {
        title: string;
        info?: JSX.Element;
    };
    advancedContent?: JSX.Element;
    hideSeperator?: boolean;
}

// Correctly typed functional component
const PageTopSection: React.FC<PopupModalProps> = ({
    isMobile,
    padding,
    simpleContent,
    advancedContent,
    hideSeperator,
}) => {
    const { theme } = useCustomTheme();

    return (
        <Box
            sx={{
                height: '8vh',
                position: 'relative',
                marginBottom: 4,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    bottom: advancedContent ? 0 : simpleContent?.info ? 0 : 20,
                    left: 0,
                    right: 0,
                    paddingLeft: getOverallPadding(isMobile, padding),
                    paddingRight: getOverallPadding(isMobile, padding),
                    flexWrap: 'wrap',
                }}
            >
                <>
                    {advancedContent ? advancedContent : null}
                    {simpleContent ? (
                        <Typography
                            variant="h5"
                            sx={{ width: '100%', textAlign: 'left' }}
                        >
                            {simpleContent.title}
                            {simpleContent.info ? (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textAlign: 'left',
                                        color: theme.palette.text.disabled,
                                    }}
                                >
                                    {simpleContent.info}
                                </Typography>
                            ) : null}
                        </Typography>
                    ) : null}
                </>
            </Box>
            {!hideSeperator ? <GradientBorder /> : null}
        </Box>
    );
};

export default PageTopSection;
