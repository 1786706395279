import axios from 'axios';

// TYPES
export enum UploadTypeNames {
    CHARACTER = 'character',
    GFX = 'gfx',
    CHAMPIONSHIP = 'championship',
    ARENA = 'arena',
    CHARACTER_DATA = 'character data',
    WEAPON = 'weapon',
    PROP = 'prop',
    MISC = 'misc',
    PACK = 'pack',
    MENU = 'menu',
}

export enum UploadOrderByFields {
    CREATED_AT = 'createdAt',
    NAME = 'name',
}

export enum UserAccountTypes {
    ADMIN = 'admin',
    MEMBER = 'member',
    MODERATOR = 'moderator',
}

// DATA
export const UploadOrderBy: UploadOrderByTypes[] = [
    {
        field: UploadOrderByFields.CREATED_AT,
        name: 'Date Uploaded',
    },
    {
        field: UploadOrderByFields.NAME,
        name: 'Name',
    },
];

// INTERFACES
export interface UploadOrderByTypes {
    field: UploadOrderByFields;
    name: string;
}

export interface Meta {
    page: {
        current: number;
        previous: number | null;
        next: number | null;
        last: number;
        first: number;
    };
    items: {
        perPage: number;
        total: number;
    };
}

export interface User {
    uuid: string;
    email: string;
    verifiedAt?: string;
    ipAddress?: string;
    lastLoggedInAt?: string;
    createdAt: string;
    updatedAt: string;
    userProfile: UserProfile;
}

export interface UploadType {
    uuid: string;
    name: UploadTypeNames;
}

export interface UserProfile {
    uuid: string;
    username: string;
    accountType: UserAccountTypes;
    paypal: string;
    patreon: string;
    facebook: string;
    twitter: string;
    youtube: string;
    canBypass: boolean;
    accountStatus: 'active' | 'suspended';
    suspended: Suspended | null;
    follows: {
        followers: number;
        following: number;
    };
    image: {
        main: string | null;
    };
    userFollowing?: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface Suspended {
    reason: string;
    by: string;
    date: string;
}

export interface Game {
    uuid: string;
    name: string;
    system: string;
    images: {
        main: string | null;
    };
}

export interface Version {
    uuid: string;
    version: number;
    createdAt: string;
    downloadLink?: string;
}

export const isUploadMember = (
    upload: Upload
): upload is Upload & { userHearted: boolean } => {
    return 'userHearted' in upload;
};

export const isVersionMember = (
    selectedVersion: Version
): selectedVersion is Version & { downloadLink: string } => {
    return 'downloadLink' in selectedVersion;
};

export interface UploadImages {
    thumbnail: string | null;
    main: string | null;
    secondary: string | null;
}

export interface Stats {
    views: number;
    hearts: number;
    downloads: number;
}

export interface UploadImage {
    key: string;
}

export interface Upload {
    uuid: string;
    name: string;
    description: string;
    miniDescription: string;
    type: UploadType;
    videoUrl?: string;
    nsfw: boolean;
    tags: string[];
    author: UserProfile;
    collaborators?: UserProfile[];
    games: Game[];
    drm: boolean;
    stats: Stats;
    versions: Version[];
    images: UploadImages;
    userHearted?: boolean;
}

// API RESPONSES
export interface PostRegisterResponse {
    data: User;
    message: 'Created user';
    status: 201;
}

export interface PostLoginResponse {
    accessToken: string;
    tokenType: 'Bearer';
    status: 200;
}

export interface GetLogoutResponse {
    message: 'Logged out';
    status: 200;
}

export interface GetUploadTypesResponse {
    data: UploadType[];
    message: 'Returned Upload Types';
    status: 200;
}

export interface GamesResponse {
    data: Game[];
    message: 'Returned Games';
    status: 200;
}

export interface PostImageResponse {
    message: 'Image Uploaded';
    status: 201;
    data: UploadImage;
}

export interface PostUploadResponse {
    message: 'Created Upload';
    status: 201;
    data: Upload;
}

export interface PostUploadLikeResponse {
    message: 'Updated Like Data';
    status: 200;
    data: {
        success: boolean;
    };
}

export interface PostUserProfileFollowResponse {
    message: 'Updated Following User Profile Data';
    status: 200;
    data: {
        success: boolean;
    };
}

export interface GetUploadVersionResponse {
    message: 'Updated Download Data';
    status: 200;
    data: Version;
}

export interface GetUploadResponse {
    message: 'Returned Upload';
    status: 200;
    data: Upload;
}

export interface GetUploadsResponse {
    message: 'Returned Uploads';
    status: 200;
    data: Upload[];
    meta: Meta;
}

export interface SuspendUserProfileResponse {
    message: string;
    status: 201;
    data: {
        success: string[];
        failed: string[];
        alreadySuspended: string[];
    };
}

export interface UnsuspendUserProfileResponse {
    message: string;
    status: 201;
    data: {
        success: string[];
        failed: string[];
        alreadyUnsuspended: string[];
    };
}

export interface UpdateUserProfileResponse {
    message: string;
    status: 200;
    data: UserProfile;
}

export interface GetUserResponse {
    message: string;
    status: 200;
    data: User;
}

export interface GetUsersResponse {
    message: string;
    status: 200;
    data: User[];
    meta: Meta;
}

export interface GetUserProfilesResponse {
    message: string;
    status: 200;
    data: UserProfile[];
    meta: Meta;
}

// API REQUESTS GET
export interface GetUsersParams {
    count?: number;
    page?: number;
    username?: string;
    uuid?: string;
}

export interface GetUserProfilesParams {
    count?: number;
    page?: number;
    username?: string;
    uuid?: string;
}

export interface GetUploadsParams {
    count?: number;
    page?: number;
    name?: string;
    type?: string;
    additionalTypeInformation?: string;
    status?: string;
    tag?: string;
    userProfile?: string;
    orderBy?: UploadOrderByFields;
    gamesArray?: string[];
    games?: string;
}

export interface GetUploadParams {
    uuid: string;
}

export interface GetUploadVersionLinkParams {
    uuid: string;
    versionUuid: string;
}

// API REQUESTS POST PUT PATCH
export interface SuspendUserProfileProps {
    uuids: string[];
    reason: string;
}

export interface UnsuspendUserProfileProps {
    uuids: string[];
    reason: string;
}

export interface UpdateUserProps {
    username?: string;
    imageMain?: string;
}

export interface CreateUploadBasicDetailsProps {
    uuid?: string;
    imageMain?: string;
    name: string;
    uploadType: string;
    videoUrl?: string;
    miniDescription: string;
    description: string;
    games: string[];
}

export interface CreateUploadFinalizeProps {
    downloadLink: string;
    additionalTypeInformation?: string;
    tags: string[];
    drm: boolean;
    nsfw: boolean;
}

export interface CreateUploadProps
    extends CreateUploadBasicDetailsProps,
        CreateUploadFinalizeProps {}

export interface AddImageProps {
    uuid: string;
    image: File;
    type: string;
    scope: string;
}

// FUNCTIONS
export const getUploadTypeByName = async (
    uploadTypes: UploadType[],
    nameToFind: UploadTypeNames
): Promise<string | null> => {
    const foundType = uploadTypes.find((type) => type.name === nameToFind);
    return foundType ? foundType.uuid : null;
};

// API CALLS
export const postImage = async (
    data: AddImageProps,
    authToken: string
): Promise<PostImageResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_IMAGE as string;
    url = url.replace(':uuid', data.uuid);

    const formData = new FormData();
    formData.append('type', data.type);
    formData.append('image', data.image);
    formData.append('scope', data.scope);

    const fetchResult = await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as PostImageResponse;
};

export const postUpload = async (
    data: CreateUploadProps,
    authToken: string,
    uuid: string,
    imageMain: string,
    uploadType?: UploadType
): Promise<PostUploadResponse> => {
    const url = process.env.REACT_APP_GET_UPLOADS as string;

    if (uploadType) {
        if (
            !(
                uploadType.name === 'character' ||
                uploadType.name === 'character data'
            )
        ) {
            data.additionalTypeInformation = undefined;
        }
    }

    data.uuid = uuid;
    data.imageMain = imageMain;

    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as PostUploadResponse;
};

export const getUpload = async (
    params: GetUploadParams,
    authToken?: string
): Promise<GetUploadResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_GET_UPLOAD as string;
    url = url.replace(':uuid', params.uuid);

    const fetchResult = await axios.get(url, {
        params: params || {},
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUploadResponse;
};

export const postUserProfileFollow = async (
    uuid: string,
    authToken: string
): Promise<PostUserProfileFollowResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_GET_USERPROFILE_FOLLOWS as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    return fetchResult.data as PostUserProfileFollowResponse;
};

export const postUploadLike = async (
    uuid: string,
    authToken: string
): Promise<PostUploadLikeResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_POST_UPLOAD_LIKE as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    return fetchResult.data as PostUploadLikeResponse;
};

export const getUploadVersionLink = async (
    params: GetUploadVersionLinkParams,
    authToken: string
): Promise<GetUploadVersionResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_GET_UPLOAD_VERSION_LINK as string;
    url = url.replace(':uuid', params.uuid);
    url = url.replace(':versionUuid', params.versionUuid);

    const fetchResult = await axios.get(url, {
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUploadVersionResponse;
};

export const getMyUploads = async (
    authToken: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    const newRequest = { ...params };

    delete newRequest?.userProfile;

    const url = process.env.REACT_APP_GET_UPLOADS_BY_USERS as string;

    return await getUploadsCore(url, authToken, newRequest);
};

export const getUploads = async (
    authToken?: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    let url = process.env.REACT_APP_GET_UPLOADS as string;

    return await getUploadsCore(url, authToken, params);
};

export const getUploadsDirection = async (
    myUploads?: boolean,
    authToken?: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    if (myUploads) {
        if (authToken) {
            return await getMyUploads(authToken, params);
        } else {
            return await getUploads(authToken, params);
        }
    } else {
        return await getUploads(authToken, params);
    }
};

export const getUploadsCore = async (
    url: string,
    authToken?: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    const newRequest = { ...params };

    if (newRequest) {
        if (newRequest.gamesArray) {
            if (newRequest.gamesArray.length > 0) {
                newRequest.games = newRequest.gamesArray.join(',');
                delete newRequest.gamesArray;
            } else {
                delete newRequest.games;
            }
        }

        if (newRequest.name === '') {
            delete newRequest.name;
        }

        if (newRequest.type === 'all') {
            delete newRequest.type;
        }
    }

    const fetchResult = await axios.get(url, {
        params: newRequest || {},
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUploadsResponse;
};

export const getUploadTypes = async (): Promise<GetUploadTypesResponse> => {
    const url = process.env.REACT_APP_GET_UPLOAD_TYPES as string;

    const fetchResult = await axios.get(url);

    return fetchResult.data as GetUploadTypesResponse;
};

export const getUserMe = async (
    authToken: string
): Promise<GetUserResponse> => {
    const url = process.env.REACT_APP_GET_USERS_ME as string;

    const fetchResult = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as GetUserResponse;
};

export const getUsers = async (
    params?: GetUsersParams
): Promise<GetUsersResponse> => {
    const url = process.env.REACT_APP_GET_USERS as string;

    const fetchResult = await axios.get(url, {
        params: params || {},
    });

    return fetchResult.data as GetUsersResponse;
};

export const getUserProfiles = async (
    params?: GetUserProfilesParams,
    authToken?: string
): Promise<GetUserProfilesResponse> => {
    const url = process.env.REACT_APP_GET_USER_PROFILES as string;

    const fetchResult = await axios.get(url, {
        params: params || {},
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUserProfilesResponse;
};

export const updateUserProfile = async (
    data: UpdateUserProps,
    authToken: string
): Promise<UpdateUserProfileResponse> => {
    const url = process.env.REACT_APP_PATCH_API_USERPROFILE as string;
    const fetchResult = await axios.patch(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as UpdateUserProfileResponse;
};

export const suspendUserProfiles = async (
    data: SuspendUserProfileProps,
    authToken: string
): Promise<SuspendUserProfileResponse> => {
    const url = process.env.REACT_APP_PATCH_API_USERPROFILE_SUSPEND as string;
    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as SuspendUserProfileResponse;
};

export const unsuspendUserProfiles = async (
    data: SuspendUserProfileProps,
    authToken: string
): Promise<UnsuspendUserProfileResponse> => {
    const url = process.env.REACT_APP_PATCH_API_USERPROFILE_UNSUSPEND as string;
    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as UnsuspendUserProfileResponse;
};

export const createUser = async (
    email: string,
    password: string,
    username: string
): Promise<PostRegisterResponse> => {
    const url = process.env.REACT_APP_POST_AUTH_USERS as string;
    const fetchResult = await axios.post(url, {
        email: email,
        password: password,
        username: username,
    });
    return fetchResult.data as PostRegisterResponse;
};

export const login = async (
    email: string,
    password: string
): Promise<PostLoginResponse> => {
    const url = process.env.REACT_APP_POST_AUTH_LOGIN as string;
    const fetchResult = await axios.post(url, {
        email: email,
        password: password,
    });
    return fetchResult.data as PostLoginResponse;
};

export const logout = async (authToken: string): Promise<GetLogoutResponse> => {
    const url = process.env.REACT_APP_POST_AUTH_LOGOUT as string;
    const fetchResult = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as GetLogoutResponse;
};

export const getGames = async (): Promise<GamesResponse> => {
    const url = process.env.REACT_APP_GET_GAMES as string;

    const fetchResult = await axios.get(url);

    return fetchResult.data as GamesResponse;
};
