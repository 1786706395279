// DownloadAndHearts.tsx
import React, { useState } from 'react';
import { Fab, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    isUploadMember,
    isVersionMember,
    Upload,
    User,
    Version,
} from '../../workers/ApiWorker';
import { Download, Favorite } from '@mui/icons-material';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';

interface CoreInformationProps {
    upload: Upload;
    selectedVersion: Version;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const DownloadAndHearts: React.FC<CoreInformationProps> = ({
    upload,
    user,
    selectedVersion,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();
    const { theme } = useCustomTheme();
    const [hearts, setHearts] = useState<number>(upload.stats.hearts);
    const [isHearted, setIsHearted] = useState<boolean>(
        isUploadMember(upload) ? upload.userHearted : false
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {
                    md: 'row', // Align the icons horizontally (side by side)
                    lg: 'row', // Keep them side by side even on large screens
                },
                justifyContent: {
                    md: 'flex-start', // Align left for smaller screens
                    lg: 'flex-end', // Align right for large screens
                },
                alignItems: 'center', // Vertically center the icons
                gap: 2, // Add space between the heart/number box and the download button
                marginTop: 2.5,
            }}
        >
            {/* Box for Heart icon and number */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5, // Adjust this value to control the space between the heart and the number
                }}
            >
                <IconButton
                    disabled={!token}
                    onClick={async () => {
                        if (token && user) {
                            try {
                                await apiWorker.postUploadLike(
                                    upload.uuid,
                                    token
                                );

                                if (isHearted) {
                                    setHearts(hearts - 1);
                                    setIsHearted(false);
                                } else {
                                    setHearts(hearts + 1);
                                    setIsHearted(true);
                                }
                            } catch (error) {
                                console.warn('could not update like');
                                if (axios.isAxiosError(error)) {
                                    if (error.response?.data) {
                                        // setError(error.response?.data.message);
                                    } else {
                                        // setError(error.message);
                                    }
                                } else {
                                    //  setError(unknownError().message);
                                }
                            }
                        }
                    }}
                    sx={{
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'transparent', // Remove hover background
                            boxShadow: 'none', // Remove hover shadow
                        },
                    }}
                >
                    <Favorite
                        sx={{
                            color: isHearted
                                ? theme.palette.error.main
                                : theme.palette.text.primary,
                        }}
                    />
                </IconButton>
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: theme.palette.text.primary,
                    }}
                >
                    {hearts}
                </Typography>
            </Box>

            {token && user && isVersionMember(selectedVersion) ? (
                <Fab color="primary" aria-label="download">
                    <Download
                        onClick={async () => {
                            try {
                                const download =
                                    await apiWorker.getUploadVersionLink(
                                        {
                                            uuid: upload.uuid,
                                            versionUuid: selectedVersion.uuid,
                                        },
                                        token
                                    );

                                window.open(
                                    download.data.downloadLink,
                                    '_blank'
                                );
                            } catch (error) {
                                console.warn('could not get download link');
                                if (axios.isAxiosError(error)) {
                                    if (error.response?.data) {
                                        // setError(error.response?.data.message);
                                    } else {
                                        // setError(error.message);
                                    }
                                } else {
                                    //  setError(unknownError().message);
                                }
                            }
                        }}
                    />
                </Fab>
            ) : null}
        </Box>
    );
};

export default DownloadAndHearts;
