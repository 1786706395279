import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchBarInput from '../../universal/inputs/SearchBarInput';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import logo from '../../../images/discovery-logo-2.png';
import Socials from '../../../helpers/Socials';
import { alpha } from '@mui/system';
import Box from '@mui/material/Box';
import UserActions from '../../user/UserActions';
import TopAppBarItems from './TopAppBarItems';
import { discoveryToolBar } from '../../../helpers/Themes';
import { User } from '../../../workers/ApiWorker';
import { useNavigate } from 'react-router-dom';

interface TopAppBarProps {
    isMobile: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    drawerOpen: boolean;
    over: boolean;
    user?: User;
}

const TopAppBar: React.FC<TopAppBarProps> = ({
    isMobile,
    drawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
    over,
    user,
}) => {
    const { themeType, theme } = useCustomTheme();
    const navigate = useNavigate();

    const handleIconClick = (value: boolean) => {
        if (value) {
            if (drawerOpen) {
                handleDrawerClose();
            } else {
                handleDrawerOpen();
            }
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: discoveryToolBar,
                ...(over && {
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }),
                ...(themeType === 'custom' && {
                    boxShadow: 'none', // Remove shadow
                    backgroundImage: 'none', // Remove gradient or light effect
                }),
                borderBottom: (theme) =>
                    `1px solid ${alpha(theme.palette.primary.light, 1)}`,
                boxShadow: 'none',
            }}
        >
            <Toolbar>
                <img
                    src={logo}
                    alt="My Logo"
                    style={{
                        height: '30px', // Set the desired height
                        filter: 'hue-rotate(180deg) saturate(0%) brightness(0) invert(1)',
                        marginRight: 12,
                        cursor: 'pointer', // Change cursor on hover
                    }}
                    onClick={() => navigate('/')}
                />
                <IconButton
                    size="small"
                    edge="start"
                    aria-label="menu"
                    sx={{
                        marginRight: isMobile ? 0 : 4, // Add margin for mobile view
                    }}
                ></IconButton>
                <Box sx={{ marginRight: isMobile ? 0 : 4 }}>
                    {window.innerWidth > 482 ? (
                        <Socials
                            twitter="https://twitter.com/dot_discovery"
                            patreon="https://www.patreon.com/dotdiscovery"
                            discord="https://discord.gg/dotdiscovery"
                            blueSky="https://bsky.app/profile/dotdiscovery.bsky.social"
                        />
                    ) : null}
                </Box>
                <SearchBarInput isMobile={isMobile} />

                {/* Add a Box to create gap before TopAppBarItems */}
                {!isMobile ? (
                    <Box sx={{ ml: 4 }}>
                        <TopAppBarItems isMobile={isMobile} user={user} />
                    </Box>
                ) : null}

                <Box sx={{ flexGrow: 1 }} />
                {!isMobile ? (
                    <Box
                        sx={{
                            display: { xs: 'flex' }, // Hides the Box for xs and shows it for md and above
                        }}
                    >
                        <Box sx={{ ml: 2 }}>
                            {' '}
                            {/* ml: 2 adds left margin */}
                            <UserActions
                                isMobile={isMobile}
                                user={user}
                            ></UserActions>
                        </Box>
                    </Box>
                ) : null}
                {isMobile ? (
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="menu"
                        sx={{
                            color: theme.palette.text.primary,
                        }}
                        onClick={() => handleIconClick(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                ) : null}
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;
