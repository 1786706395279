import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface TextInputProps {
    caption: string;
}

const CaptionInput: React.FC<TextInputProps> = ({ caption }) => {
    const { theme } = useCustomTheme();

    return (
        <Typography
            variant="body2"
            sx={{
                color: theme.palette.warning.main,
                marginTop: '4px',
                textAlign: 'left', // Ensure text is aligned left
                display: 'block', // Use block display to occupy the full width
            }}
        >
            {caption}
        </Typography>
    );
};

export default CaptionInput;
