import React, {
    ChangeEvent,
    KeyboardEvent as ReactKeyboardEvent,
    useState,
} from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import LabelInput from './LabelInput';
import CaptionInput from './CaptionInput';
import Tag from '../chips/Tag';
import Box from '@mui/material/Box';

interface TagInputProps {
    id: string;
    label: string;
    handleChange: (field: string, value: any[]) => void; // Update this to expect an array
    handleBlur: (e: React.FocusEvent<any>) => void;
    hasSubmitted: boolean;
    touched?: boolean;
    errors?: string[] | string;
    value: string[]; // Ensure this is an array of strings
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
}

const TagInput: React.FC<TagInputProps> = ({
    id,
    label,
    handleChange,
    handleBlur,
    hasSubmitted,
    touched,
    errors,
    value,
    enableLabel,
    isMobile,
    caption,
}) => {
    const [inputValue, setInputValue] = useState<string>(''); // To store current input

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    // Function to handle removing a tag
    const removeTag = (index: number) => {
        const newTags = value.filter((_, i) => i !== index);
        handleChange(id, newTags);
    };

    const handleKeyPress = (e: ReactKeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            // Capture comma or Enter key
            e.preventDefault();
            if (inputValue.trim() !== '') {
                const newTags = [...value, inputValue.trim()]; // Always an array due to default
                handleChange(id, newTags); // Ensure value is an array
                setInputValue(''); // Clear input
            }
        }
    };

    return (
        <FormControl fullWidth margin="normal">
            <Grid
                container
                alignItems={isMobile ? 'flex-start' : 'center'}
                spacing={2}
            >
                {/* Label Area */}
                {!enableLabel && (
                    <LabelInput
                        label={label}
                        enableLabel={enableLabel}
                        isMobile={isMobile}
                        caption={caption}
                    />
                )}

                {/* Text Field Area */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                    lg={enableLabel ? 12 : 9.8}
                    xl={enableLabel ? 12 : 10.25}
                >
                    <>
                        {/* Display tags */}
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginBottom: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap', // Wrap tags onto a new line when needed
                                    gap: 1,
                                    overflow: 'auto', // Add scroll if needed for small screens
                                }}
                            >
                                {value.map((tag, index) => (
                                    <Tag
                                        key={index}
                                        text={tag}
                                        removable={true}
                                        onDelete={() => removeTag(index)}
                                    />
                                ))}
                            </Box>
                        </div>
                        <TextField
                            fullWidth
                            id={id}
                            name={id}
                            label={
                                enableLabel
                                    ? value && value.length > 0
                                        ? label
                                        : undefined // Show label if enabled and value > 0
                                    : undefined
                            }
                            placeholder={enableLabel ? label : undefined}
                            value={inputValue}
                            onKeyDown={handleKeyPress}
                            onChange={handleInputChange}
                            onBlur={(e) => {
                                handleBlur(e);
                            }}
                            error={touched && Boolean(errors)}
                            helperText={touched && errors}
                            margin="none"
                            disabled={hasSubmitted}
                            type={'text'}
                        />
                        {enableLabel && caption && (
                            <CaptionInput caption={caption} />
                        )}
                    </>
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default TagInput;
