// HomePage.tsx
import React from 'react';
import { CardContent, Grid, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { Upload } from '../../workers/ApiWorker';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

interface DescriptionAreaProps {
    upload: Upload;
    image: string | null;
}

const DescriptionArea: React.FC<DescriptionAreaProps> = ({ upload, image }) => {
    const { theme } = useCustomTheme();

    function sanitizeQuillHtml(data: string) {
        // Convert opening <ol><li data-list="bullet"> to <ul><li>
        let sanitizedHtml = data.replace(
            /<ol><li data-list="bullet">/g,
            '<ul><li>'
        );

        // Replace closing tags only for the unordered lists (bullets)
        sanitizedHtml = sanitizedHtml.replace(/<\/li><\/ol>/g, '</li></ul>');

        return sanitizedHtml;
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card
                sx={{
                    marginTop: 2,
                    // We might need to remove the below line as its laggy and doesn't work on all browsers
                    //backdropFilter: 'blur(30px)',
                }}
            >
                <CardContent
                    sx={
                        image
                            ? {
                                  position: 'relative',
                                  overflow: 'hidden',
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                              }
                            : {}
                    }
                >
                    {image && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: 'full',
                                overflow: 'hidden',
                                backgroundColor: 'rgb(21,30,48)',
                                opacity: 0.5,
                            }}
                        >
                            <img
                                src={image} // Use the image prop dynamically
                                alt="blurred background"
                                style={{
                                    backgroundSize: 'cover',
                                    objectPosition: 'bottom',
                                    width: '100vw', // Ensure full viewport width
                                    height: '100vh', // Ensure full viewport height
                                    filter: 'blur(150px)',
                                    opacity: 0.5,
                                }}
                            />
                        </Box>
                    )}
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.secondary,
                                marginBottom: -1,
                                '& h1, & h2, & h3, & h4, & h5, & h6, & p, & ol, & ul':
                                    {
                                        margin: 0,
                                    },
                            }}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeQuillHtml(upload.description),
                            }}
                        ></Typography>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default DescriptionArea;
