// UserAvatar.tsx
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { UserProfile } from '../../workers/ApiWorker';
import { useNavigate } from 'react-router-dom';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { discoveryGreyBlue, discoveryToolBar } from '../../helpers/Themes'; // Assuming you're using MUI's theme

interface UserAvatarProps {
    backup: string;
    dimensions?: number;
    marginRight?: number;
    padding?: number;
    border?: boolean;
    key?: number;
    total?: boolean;
    userProfile?: UserProfile;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
    backup,
    dimensions,
    marginRight,
    padding,
    border,
    key,
    total,
    userProfile,
}) => {
    const { theme } = useCustomTheme();

    const navigate = useNavigate();

    return (
        <Avatar
            key={key}
            src={userProfile?.image.main || undefined}
            sx={{
                width: dimensions ? dimensions : undefined, // If dimensions provided, use it, else default to 36
                height: dimensions ? dimensions : undefined, // Same for height
                border: border
                    ? `2px solid ${theme.palette.primary.main}`
                    : undefined,
                padding: padding ? `${padding}px` : undefined, // If padding is provided, apply it
                mr: marginRight ? marginRight : undefined, // If marginRight is provided, apply it
                color: theme.palette.text.primary,
                cursor: userProfile ? 'pointer' : undefined, // Show pointer cursor when clickable
                backgroundColor: userProfile?.image.main
                    ? discoveryToolBar
                    : theme.palette.text.disabled,
            }}
            onClick={() => {
                if (userProfile) {
                    const userProfileUuid = userProfile.uuid;
                    navigate(`/${userProfile.username}`, {
                        state: { userProfileUuid },
                    }); // Navigate only if userProfile exists
                }
            }}
        >
            {total
                ? backup
                : !userProfile?.image.main
                  ? backup.slice(0, 1).toUpperCase()
                  : null}
        </Avatar>
    );
};

export default UserAvatar;
