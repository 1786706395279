import React, { useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { UploadFile } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import PreviewCard from '../cards/PreviewCard';
import { Game, UploadType, UploadTypeNames } from '../../../workers/ApiWorker';
import {
    PreviewCardSizeArenas,
    PreviewCardSizeOthers,
} from '../../../helpers/UploadCardSizings';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface ImageUploadProps {
    handleImageUpload: (value?: File) => void;
    uploadData: {
        title: string;
        description: string;
        type: UploadType | undefined;
        games: Game[];
    };
    file?: File;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
    handleImageUpload,
    file,
    uploadData,
}) => {
    const { theme } = useCustomTheme();
    const [selectedImage, setSelectedImage] = useState<File | undefined>(file); // State for selected image

    // Function to handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Only allow one image
        if (file) {
            // Clean up any previous object URL before creating a new one
            handleImageUpload(file);
            setSelectedImage(file); // Store file temporarily in state
        }

        // Reset the file input so the same image can be uploaded again
        event.target.value = '';
    };

    // Clear the uploaded image
    const clearImage = () => {
        setSelectedImage(undefined);
        handleImageUpload(undefined); // Call the upload handler with no image

        // Reset the file input so that the same image can be uploaded again
        const fileInput = document.getElementById(
            'imageUploadInput'
        ) as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // Reset the file input field
        }
    };

    // Trigger file input on card click
    const handleCardClick = () => {
        if (!selectedImage) {
            // Only trigger file input if no image is selected
            document.getElementById('imageUploadInput')?.click();
        }
    };

    return (
        <Box>
            {selectedImage && uploadData.type ? ( // This checks if both are truthy
                <Card
                    sx={{
                        backgroundColor: 'transparent',
                        border: `2px solid ${theme.palette.primary.main}`, // Use backticks for string interpolation
                        position: 'relative',
                    }}
                    onClick={handleCardClick}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: theme.palette.text.primary,
                            zIndex: 10,
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                            },
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            clearImage();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        marginTop={5}
                        marginBottom={5}
                        padding={1}
                        spacing={2}
                    >
                        {Array.from({ length: 1 }).map((_, index) => (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    ...(uploadData.type &&
                                    uploadData.type.name ===
                                        UploadTypeNames.ARENA
                                        ? PreviewCardSizeArenas
                                        : PreviewCardSizeOthers),
                                }}
                                key={index}
                            >
                                <PreviewCard
                                    title={uploadData.title}
                                    description={uploadData.description}
                                    image={URL.createObjectURL(selectedImage)}
                                    games={uploadData.games}
                                    type={uploadData.type!} // Use non-null assertion operator here
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            ) : (
                <Card
                    sx={{
                        cursor: selectedImage ? 'default' : 'pointer',
                        transition: 'transform 0.2s',
                        backgroundColor: 'transparent',
                        border: `2px solid ${theme.palette.primary.main}`, // Use backticks for string interpolation
                        padding: '20px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                    onClick={handleCardClick}
                >
                    <UploadFile
                        sx={{
                            fontSize: '164px',
                            color: theme.palette.primary.main,
                            marginBottom: '10px',
                            marginTop: '40px',
                        }}
                    />
                    <Typography
                        variant="body2"
                        sx={{
                            marginTop: '10px',
                            color: theme.palette.text.secondary,
                            marginBottom: '40px',
                        }}
                    >
                        This image serves as the thumbnail for your submission
                        and as the background for its page.
                    </Typography>
                </Card>
            )}
            <input
                id="imageUploadInput"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </Box>
    );
};

export default ImageUpload;
