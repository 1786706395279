import React, { useState } from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { Upload, User, Version } from '../../workers/ApiWorker';
import { convertSimple } from '../../helpers/Date';
import Box from '@mui/material/Box';
import UserAvatar from '../user/UserAvatar';
import { useApi } from '../../contexts/ApiContext';

interface DetailedInformationProps {
    upload: Upload;
    version: Version;
    isMobile: boolean;
    user?: User;
}

const DetailedInformation: React.FC<DetailedInformationProps> = ({
    upload,
    version,
    isMobile,
    user,
}) => {
    const { token } = useApi();
    const { theme } = useCustomTheme();

    return (
        <>
            <Grid
                container
                gap={5}
                textAlign="center"
                sx={{
                    justifyContent: isMobile ? 'center' : 'left',
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <Grid item>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        date
                    </Typography>
                    <Typography variant="h5">
                        {convertSimple(version.createdAt)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        {upload.stats.views + 1}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        views
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        games
                    </Typography>
                    <Typography variant="h5">
                        {upload.games[0].name.toUpperCase()}
                    </Typography>
                    {upload.games.length > 1 && (
                        <Tooltip
                            title={
                                <Box sx={{ padding: 1 }}>
                                    {upload.games
                                        .slice(1)
                                        .map((game, index) => (
                                            <Typography
                                                key={index}
                                                variant="body2"
                                            >
                                                {game.name}
                                            </Typography>
                                        ))}
                                </Box>
                            }
                            placement="bottom"
                            arrow
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme.palette.text.disabled,
                                    cursor: 'pointer',
                                }}
                            >
                                + {upload.games.length - 1} more
                            </Typography>
                        </Tooltip>
                    )}
                </Grid>
                <Grid
                    item
                    xs={isMobile ? 12 : true}
                    marginTop={isMobile ? -2 : undefined}
                >
                    {token && user ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: isMobile ? 'center' : 'right',
                                gap: 1,
                            }}
                        >
                            <Button variant="contained" type="submit">
                                Subscribe
                            </Button>
                            <Button variant="contained" type="submit">
                                Report
                            </Button>
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
            <Grid>
                <Grid item>
                    {upload.collaborators && upload.collaborators.length > 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                flexWrap: 'wrap', // Allow items to wrap to the next line
                                overflow: 'hidden',
                                justifyContent: {
                                    xs: 'center',
                                    lg: 'flex-start',
                                }, // Center on small, left on large
                            }}
                        >
                            {upload.collaborators.map((collaborator, index) => (
                                <UserAvatar
                                    key={index}
                                    backup={collaborator.username}
                                    dimensions={36}
                                    border={true}
                                    userProfile={collaborator}
                                />
                            ))}
                        </Box>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default DetailedInformation;
