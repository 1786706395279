import React from 'react';
import IconButton from '@mui/material/IconButton';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SiPatreon } from 'react-icons/si';
import { useCustomTheme } from '../contexts/ThemeContext';
import { FaBluesky } from 'react-icons/fa6';
import { alpha } from '@mui/material';

interface SocialProps {
    twitter?: string;
    discord?: string;
    patreon?: string;
    blueSky?: string;
}

const Socials: React.FC<SocialProps> = ({
    blueSky,
    twitter,
    patreon,
    discord,
}) => {
    const { theme, themeType } = useCustomTheme();

    const social = (icon: JSX.Element, link: string) => {
        return (
            <IconButton
                size="small"
                edge="start"
                sx={{
                    mr: 2,
                    ...(themeType === 'custom' && {
                        border: '1px solid',
                        backgroundColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        borderColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        '&:hover': {
                            backgroundColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        },
                    }),
                }}
                onClick={() => (window.location.href = link)}
            >
                {icon}
            </IconButton>
        );
    };

    return (
        <>
            {/* Conditionally render Twitter button */}
            {twitter && social(<FaXTwitter />, twitter)}

            {/* Conditionally render Discord button */}
            {discord && social(<FaDiscord />, discord)}

            {/* Conditionally render Patreon button */}
            {patreon && social(<SiPatreon />, patreon)}

            {/* Conditionally render Patreon button */}
            {blueSky && social(<FaBluesky />, blueSky)}
        </>
    );
};

export default Socials;
