// AppRoutes.tsx
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { links } from '../helpers/Links';
import { User } from '../workers/ApiWorker';

interface AppRoutesProps {
    isMobile: boolean;
    padding: number;
    user?: User;
    width?: number;
}

const AppRoutes: React.FC<AppRoutesProps> = ({
    isMobile,
    padding,
    user,
    width,
}) => {
    const [pages, setPages] = React.useState(
        links(isMobile, padding, user, width)
    );

    useEffect(() => {
        setPages(links(isMobile, padding, user, width));
    }, [isMobile, padding, user, width]);

    return (
        <Routes>
            {pages.map((item, index) => {
                return (
                    <Route
                        key={item.name}
                        path={item.path}
                        element={item.page}
                    />
                );
            })}
        </Routes>
    );
};

export default AppRoutes;
