// HomePage.tsx
import React from 'react';
import Box from '@mui/material/Box';
import { Fade, Grid, Pagination } from '@mui/material';
import {
    PreviewCardSizeOthers,
    SearchCardSizeOthers,
} from '../../helpers/UploadCardSizings';
import { Upload } from '../../workers/ApiWorker';
import { fadeTimeout } from '../../helpers/Themes';
import LoadingPreviewCard from '../universal/cards/LoadingPreviewCard';
import PreviewCard from '../universal/cards/PreviewCard';
import NoResultsFound from '../universal/loader/NoResultsFound';

interface SearchResultsProps {
    isMobile: boolean;
    uploads: Upload[];
    loadingResults: boolean;
    previewCardSizes:
        | typeof PreviewCardSizeOthers
        | typeof SearchCardSizeOthers;
    lastPage: number;
    currentPage: number;
    handlePageChange: (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({
    isMobile,
    loadingResults,
    uploads,
    previewCardSizes,
    lastPage,
    currentPage,
    handlePageChange,
}) => {
    return (
        <Box sx={{ marginTop: 2 }}>
            <>
                <Grid container spacing={2} marginBottom={isMobile ? 2 : 0}>
                    {loadingResults ? (
                        Array.from({
                            length: 12,
                        }).map((_, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                sx={previewCardSizes}
                            >
                                <Fade in={true} timeout={fadeTimeout}>
                                    <div>
                                        {/* Show PreviewCard if uploads has data */}
                                        <LoadingPreviewCard invisible={false} />
                                    </div>
                                </Fade>
                            </Grid>
                        ))
                    ) : // If not loading
                    uploads.length > 0 ? (
                        uploads.map((upload) => (
                            <Grid
                                item
                                xs={12}
                                key={upload.uuid}
                                sx={previewCardSizes}
                            >
                                <Fade in={true} timeout={fadeTimeout / 2}>
                                    <div>
                                        {/* Show PreviewCard if uploads has data */}
                                        <PreviewCard
                                            uuid={upload.uuid}
                                            title={upload.name}
                                            username={upload.author.username}
                                            description={upload.miniDescription}
                                            image={upload.images.main}
                                            games={upload.games}
                                            type={upload.type}
                                        />
                                    </div>
                                </Fade>
                            </Grid>
                        ))
                    ) : (
                        <NoResultsFound />
                    )}
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: isMobile ? 10 : 3,
                        marginTop: 3,
                    }}
                >
                    {uploads.length > 0 ? (
                        <Fade in={true} timeout={fadeTimeout}>
                            <Pagination
                                showFirstButton
                                showLastButton
                                count={lastPage}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Fade>
                    ) : null}
                </Box>
            </>
        </Box>
    );
};

export default SearchResults;
