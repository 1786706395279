// Breadcrumbs.tsx
import React from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Upload } from '../../workers/ApiWorker';
import Tag from '../universal/chips/Tag';
import { useNavigate } from 'react-router-dom';

interface TagsProps {
    upload: Upload;
}

const Tags: React.FC<TagsProps> = ({ upload }) => {
    const navigate = useNavigate();

    const handleTagClick = (searchValue: string) => {
        localStorage.removeItem('searchParams');
        const query = searchValue.trim();
        const searchId = new Date().getTime();
        if (query) {
            navigate(
                `/search?query=${encodeURIComponent(query)}&searchId=${searchId}`,
                { replace: true }
            );
        }
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap', // Allow items to wrap to the next line
                    marginTop: 3,
                    gap: 1, // Maintain spacing between items
                    overflow: 'hidden', // Optional: Prevent horizontal scrolling if necessary
                }}
            >
                {upload.tags.map((tag, index) => (
                    <Tag
                        key={index}
                        text={tag}
                        searchable={true}
                        onClick={() => {
                            handleTagClick(tag.toUpperCase());
                        }}
                    />
                ))}
            </Box>
        </Grid>
    );
};

export default Tags;
